<template>
  <div :class="$style.actions">
    <div :class="$style.title">
      <component
        :is="titleComponent"
        :title="title"
      />
    </div>

    <router-view />
  </div>
</template>

<script>
import Title from '@/components/title.vue';

export default {
  components: {
    Title,
  },
  computed: {
    title() {
      return this.$route.meta?.title;
    },
    titleComponent() {
      const component = this.$route.meta?.titleComponent;

      if (component) {
        return component;
      }

      return Title;
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  .title {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
  }
}
</style>
